@import url('../common/variables.css');
@import url('../common/global.css');

/* Footer Styling */
.footer {
    background-color: var(--dunkelgruen);
    padding: 20px 0;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-left {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.footer-logo a img {
    max-width: 200px;
    margin-bottom: 20px;
    display: block;
}

.footer-nav ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.footer-nav ul li {
    margin: 0 10px 10px 0;
}

.footer a {
    color: #fff;
}

.footer-nav ul li a {
    font-family: 'PT Sans', Arial, sans-serif;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    font-size: 1em;
    padding: 5px 0;
    white-space: normal;
}

.footer-nav ul li a:hover,
.footer-nav ul li a:focus {
    text-decoration: underline;
}

/* Für Tastaturnavigation, fügen wir einen subtilen Fokusindikator hinzu */
.footer-nav ul li a:focus-visible {
    outline: 2px solid rgba(255, 255, 255, 0.5);
    outline-offset: 2px;
}

.footer-bottom {
    margin-top: 20px;
    font-size: 0.9em;
    text-align: left;
}

.footer-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    align-items: flex-end;
}

.footer-social-icon {
    color: #fff;
    font-size: 1.5em;
    margin: 10px 0;
    text-decoration: none;
    padding: 5px;
}

.footer-social-icon:hover,
.footer-social-icon:focus {
color: var(--sand)}

.footer-social-icon:focus-visible {
    outline: 2px solid #fff;
    outline-offset: 2px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

/* Focus visibility */
.user-is-tabbing *:focus {
    outline: 3px solid #fff;
    outline-offset: 3px;
}

.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000;
    color: white;
    padding: 8px;
    z-index: 100;
}

.skip-link:focus {
    top: 0;
}
/* Media Queries for responsive design */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .footer-left,
    .footer-right {
        width: 100%;
        text-align: center;
    }

    .footer-logo a img {
        margin: 0 auto 20px;
    }

    .footer-nav ul {
        justify-content: center;
    }

    .footer-nav ul li {
        margin: 5px;
    }

    .footer-bottom {
        font-size: 0.8em;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .footer-right {
        margin-top: 20px;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }

    .footer-nav ul li a {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .footer-nav ul li {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .footer-nav ul li a {
        display: block;
        padding: 5px;
    }
}