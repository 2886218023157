/* Import fonts */
@font-face {
    font-family: 'PT Sans';
    src: url('../../fonts/PTSans-Regular.woff2') format('woff2'),
         url('../../fonts/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../fonts/PTSans-Bold.woff2') format('woff2'),
         url('../../fonts/PTSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../fonts/PTSans-Italic.woff2') format('woff2'),
         url('../../fonts/PTSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GrueneType';
    src: url('../../fonts/GrueneType.woff2') format('woff2'),
         url('../../fonts/GrueneType.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Base styles */
body {
    font-family: 'PT Sans', Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--font-color);
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.5s, color 0.5s;
}

h1, h2 {
    font-family: 'GrueneType', Arial, sans-serif;
    color: var(--font-color-h);
}

h1 {
    font-size: 2.25em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-family: 'GrueneType', Arial, sans-serif;
    color: var(--font-color-h3);
    font-size: 1.5em;
}

@media (max-width: 768px) {
    body {
        font-size: 1.4em;
        line-height: 1.6;
    }
    h1 {
        font-size: 1.8em !important;
        line-height: 1.4;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1.15em;
    }
}
